<template>
  <div class="page page-contact">
    <h1 style="margin-bottom:30px">Kontakt</h1>

    <div class="row row-gutter-20">
      <div class="col-6">
        <div class="contact-wrap">
          <p class="icon"><span class="material-icons">call</span></p>
          <h3>Rufen Sie uns an</h3>
          <h4>0157 836 661 61</h4>
        </div>
      </div>
      <div class="col-6">
        <div class="contact-wrap">
          <p class="icon"><img src="../assets/images/wa.svg" height="36" style="display:inline-block;"></p>
          <h3>WhatsApp</h3>
          <h4>0157 836 661 61</h4>
        </div>
      </div>
      <div class="col-6">
        <div class="contact-wrap">
          <p class="icon"><span class="material-icons">email</span></p>
          <h3>Schreiben Sie uns eine E-Mail</h3>
          <h4>info@intime-autovermietung.de</h4>
        </div>
      </div>
      <div class="col-6">
        <div class="contact-wrap">
          <p class="icon"><span class="material-icons">markunread_mailbox</span></p>
          <h3>Auf dem Postweg</h3>
          <h4>InTime Autovermietung<br>Jacob-Schüle-Straße 29<br>73655 Plüderhausen</h4>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'contact',
  data () {
    return {}
  },
  methods: {},
  mounted () {
  }
}
</script>

<style lang="scss">

  .contact-wrap {
    background: #f9f9f9;
    text-align: center;
    padding: 30px;
    min-height: 255px;

    p.icon {
      span {
        font-size: 40px;
      }
    }

    h3 {
      margin: 0;
    }

    h4 {
      font-weight: 400;
    }
  }
</style>
